import BitgreenIcon from "../../../components/icons/bitgreenIcon";
import { assetChainModules } from "./assetChain";

const bitgreenTestnet = {
  name: "Bitgreen Testnet",
  icon: <BitgreenIcon />,
  identity: "polkadot",
  sub: "polkadot",
  value: "bitgreenTestnet",
  chain: "polkadot",
  symbol: "tBBB",
  decimals: 18,
  chainIcon: "polkadot",
  color: "#E6007A",
  colorSecondary: "rgba(230, 0, 122, 0.1)",
  buttonColor: "#E6007A",
  hidden: false,
  modules: assetChainModules,
  para: {
    relay: "Polkadot",
    id: "2048",
  },
};

export default bitgreenTestnet;
